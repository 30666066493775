import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { IBranch } from "../../../../Shared/Interfaces/IBranch";
import { PageHeaderContainer } from "../CompaniesPage/styledComponents";
import Loader from "../../../Global/Loader";
import CTAButton from "../../../Global/Buttons/CTAButton";
import {
  DefaultTable,
  DefaultTableBox,
  DefaultTableCell,
  DefaultTableHeader,
  DefaultTableHeaderContainer,
  DefaultTableRow,
} from "../../../Global/DefaultTable/styledComponents";
import CustomDialog from "../../../Global/CustomDialog";
import AddEditBranchSideForm from "./AddEditBranchSideForm";
import {
  useActivateDeactivateBranchMutation,
  useGetCompanyBranchesQuery,
} from "../../../../features/branch/branchApiSlice.ts";
import ActiveSwitch from "../../../../features/ActiveSwitch.tsx";
import { handleRTKError } from "../../../../Shared/Functions/RTKErrorHandler.ts";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DefaultTableFooter from "../../../Global/DefaultTable/DefaultPaginator";
import {useSelector} from "react-redux";
import {RootState} from "../../../../Redux/store.ts";
import {IUserInfo} from "../../../../Shared/Interfaces/IUserInfo.ts";
import { UserRoles } from "../../../../Shared/Constants/enums.ts";
import DefaultDialog from "../../../Global/DefaultDialog/index.tsx";
import { useDeleteBranchMutation } from "../../../../features/company/companyApiSlice.ts";

function BranchesPage() {
  const userInfo = useSelector((state: RootState) => state.auth.userInfo) as IUserInfo;
  let companyID = useParams().companyID;
  if (userInfo.userRole !== UserRoles.OWNER)
    companyID = userInfo.companyID;

  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [pagesCount, setPagesCount] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteBranchID, setDeletedBranchID] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [branchItem, setBranchItem] = useState<IBranch | null>(null);



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (_event: any, reason: any) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    setIsEdit(false);
  };
  const queryParams = "pageSize=" + pageSize + "&pageNumber=" + pageNumber;

  const { data, refetch, error, isError, isFetching, isSuccess } =
    useGetCompanyBranchesQuery({ companyID, queryParams });

  const [deleteBranch, {isError: deleteIsError, error: deleteError, isLoading: deleteIsLoading}] = useDeleteBranchMutation();

  handleRTKError(isError, error);
  useEffect(() => {
    if (isSuccess) {
      setPagesCount(data?.pagesCount);
    }
  }, [isSuccess]);

  useEffect(() => {
    handleRTKError(deleteIsError, deleteError);
  }, [deleteIsError, deleteError]);
  // TODO: get data from userData in store
  // const canAddBranch = !data?.meta?.companyPackage?.configurations?;
  
  const canAddBranch =  data?.meta?.package?.configurations?.numberOfBranches > data?.result.length ? true : false;

  const onEditBranch = (branch: IBranch) => {
    console.log(branch)
    setOpen(true);
    setIsEdit(true);
    setBranchItem(branch);
  };

  const actionAfterSubmit = () => {
    setOpen(false);
    setIsEdit(false);
    refetch();
  }

  const confirmDeleteBranch = () => {
    const branch = (data?.result?.find((branch: IBranch) => branch?._id === deleteBranchID) as IBranch)
    setDialogOpen(false);

    deleteBranch({
      companyID:branch.companyID, 
      brandID: branch.brandID, 
      branchID: deleteBranchID,
    });
  }

  useEffect(() => {
    if (deleteIsLoading === false)
      refetch();
  }, [deleteIsLoading])

  const openDialConfirmation = (branchID: string) => {
    setDialogOpen(true);
    setDeletedBranchID(branchID);
  }

  return (
    <div>
      {(isFetching || deleteIsLoading) && <Loader />}
      <DefaultDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        dialogTitle="Do You Want Delete This Branch ?"
        dialogText={`If you deleted a branch, you won't able to restore it.
          Ratings and users under this branch will be delete as well.`}
        confirmFn={confirmDeleteBranch}
      />
      <PageHeaderContainer>
        {canAddBranch && (
          <CTAButton action={handleClickOpen} buttonText="Add Branch" />
        )}
      </PageHeaderContainer>
      <DefaultTableBox>
        <DefaultTableHeaderContainer>
          <DefaultTableHeader style={{ width: "calc(100% / 7)" }}>
            Branch Name
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 7)" }}>
            Company Name
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 7)" }}>
            Brand Name
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 7)" }}>
            Google Maps Url
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 7)" }}>
            Latitude And Longitude
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 7)" }}>
            Active
          </DefaultTableHeader>
          <DefaultTableHeader style={{ width: "calc(100% / 7)" }}>
            Actions
          </DefaultTableHeader>
        </DefaultTableHeaderContainer>
        <DefaultTable>
          <tbody>
            {data?.result.map((branch: IBranch & {brandName?: string;}) => (
              <DefaultTableRow key={`${branch._id}`}>
                <DefaultTableCell>{branch?.name}</DefaultTableCell>
                <DefaultTableCell>{data?.meta?.companyName}</DefaultTableCell>
                <DefaultTableCell>{branch?.brandName}</DefaultTableCell>
                <DefaultTableCell>{branch?.locationURL}</DefaultTableCell>
                <DefaultTableCell>
                  {branch.latitude || branch.longitude
                    ? branch.latitude + ", " + branch.longitude
                    : ""}
                </DefaultTableCell>
                <DefaultTableCell>
                  <ActiveSwitch
                    entity={branch}
                    mutation={useActivateDeactivateBranchMutation}
                  />
                </DefaultTableCell>

                <DefaultTableCell>
                  <IconButton onClick={() => openDialConfirmation(branch?._id?.toString() as string)}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton>
                      <EditIcon onClick={() => onEditBranch(branch)}/>
                    </IconButton>
                </DefaultTableCell>
              </DefaultTableRow>
            ))}
          </tbody>
        </DefaultTable>
        <DefaultTableFooter count={pagesCount} setPageNumber={setPageNumber} />
      </DefaultTableBox>

      {/* Dialog Area */}
      <CustomDialog
        open={open}
        handleClose={handleClose}
        titleText={`${isEdit ? "Edit" : "Add"} Branch`}
      >
        <AddEditBranchSideForm 
          isEdit={isEdit}
          actionAfterSubmit={actionAfterSubmit}
          branchItem={branchItem as IBranch}  />
      </CustomDialog>
    </div>
  );
}

export default BranchesPage;

// interface ITranslation {
//     addBranch: string;
//     editBranch: string;
//     branchName: string;
//     package: string;
//     actions: string;
// }
// const lang = getLanguage();
// const staticTextKeys = [
//     "addField",
//     "editField",
//     "fieldName",
//     "fieldType",
//     "category",
//     "actions",
//     "positiveSuggestions",
//     "negativeSuggestions",
// ];
