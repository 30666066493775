import { apiSlice } from "../apiSlice";

export const companiesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addCompany: builder.mutation({
      query: (payload) => ({
        url: "/companies",
        method: "POST",
        body: payload,
      }),
    }),
    activateDeactivateCompany: builder.mutation({
      query: ({ id, isActive }) => ({
        url: `/companies/${id}/active/${isActive}`,
        method: "PATCH",
      }),
    }),
    getAllCompanies: builder.query({
      query: (queryParams) => `/companies/${queryParams}`,
    }),
    getCompanyPackage: builder.query({
      query: (companyID) => `/companies/${companyID}/package`,
    }),
    addCompanyFormField: builder.mutation({
      query: ({ companyID, payload, isOwner }) => ({
        url: isOwner ? "/form-fields" : `/companies/${companyID}/form-fields`,
        method: "POST",
        body: payload,
      }),
    }),
    getCompanyFormFields: builder.query({
      query: ({ companyID, isOwner }) =>
        isOwner ? "/form-fields" : `/companies/${companyID}/form-fields`,
    }),
    updateCompanyFormField: builder.mutation({
      query: ({ companyID, payload, formFieldID, isOwner }) => ({
        url: isOwner
          ? `/form-fields/${formFieldID}`
          : `/companies/${companyID}/form-fields/${formFieldID}`,
        method: "PUT",
        body: payload,
      }),
    }),
    deleteCompanyFormField: builder.mutation({
      query: ({ companyID, formFieldID, isOwner }) => ({
        url:
          (isOwner ? "/form-fields/" : `/companies/${companyID}/form-fields/`) +
          formFieldID,
        method: "DELETE",
      }),
    }),
    getCompanyUsers: builder.query({
      query: ({ companyID, queryParams }) =>
        `/companies/${companyID}/users${queryParams}`,
    }),
    getCompanyInfoById: builder.query({
      query: ({ companyID, queryParams }) =>
        `/companies/${companyID}${queryParams}`,
    }),
    getCompanyRatings: builder.query({
      query: ({ companyID, branchID, queryParams }) => {
        if (branchID) {
          return `/branches/${branchID}/ratings${queryParams}`;
        } else {
          return `/companies/${companyID}/ratings${queryParams}`;
        }
      },
    }),
    getCompanyBrands: builder.query({
      query: (companyID) => `/companies/${companyID}/brands`,
    }),
    deleteBranch: builder.mutation({
      query: ({ companyID, brandID, branchID }) => ({
        url: `/companies/${companyID}/brands/${brandID}/branches/${branchID}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddCompanyMutation,
  useActivateDeactivateCompanyMutation,
  useGetAllCompaniesQuery,
  useGetCompanyPackageQuery,
  useAddCompanyFormFieldMutation,
  useGetCompanyFormFieldsQuery,
  useUpdateCompanyFormFieldMutation,
  useDeleteCompanyFormFieldMutation,
  useGetCompanyUsersQuery,
  useGetCompanyInfoByIdQuery,
  useGetCompanyRatingsQuery,
  useGetCompanyBrandsQuery,
  useDeleteBranchMutation,
} = companiesApiSlice;
